var Singleton = (function () {
  var 
      instance,
      self = this
  ;

  self.setupValidatorDefaults = function () {
    if (typeof jQuery.validator === 'undefined') {
      return;
    }

    jQuery.validator.addMethod('telephone', function (number, element) {
      number = number.replace(/\s+/g, '');
      return this.optional(element) || number.length > 5 &&
      number.match(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/);
    }, 'Please enter a valid telephone number');
  }

  self.setupDocumentEvents = function () {
    if (typeof document != 'object') {
        return;
    }

    jQuery(document).on('ajax-submitting', function (event) {
        if (jQuery(event.target).find('.submitting').length > 0) {
            jQuery(event.target).find('.submitting').show();
        }
    });

    jQuery(document).on('ajax-submitting-done', function (event) {
        if (jQuery(event.target).find('.submitting').length > 0) {
            jQuery(event.target).find('.submitting').hide();
        }

        if (jQuery(event.target).find('.thank-you').length > 0) {
            jQuery(event.target).find('.thank-you').show();
        }
    });

    jQuery(document).on('ajax-submitting-failed', function (event) {
        if (jQuery(event.target).find('.submitting').length > 0) {
            jQuery(event.target).find('.submitting').hide();
        }

        if (jQuery(event.target).find('.form-group').length > 0) {
            jQuery(event.target).find('.form-group').show();
        }
    });
  }

  self.setupSmoothLinkScrolling = function (sel) {
      jQuery(sel).on('click',  function (event) {
          var hash = jQuery(this).prop('hash');

          if (hash.length < 1 || jQuery(hash).length < 1) {
              return;
          }

          var 
              hashTarget = jQuery(hash),
              hashTargetCoords = hashTarget[0].getBoundingClientRect(),
              distanceBetween = parseInt(hashTarget.offset().top - jQuery(this).offset().top),
              headerHeight = jQuery('header').outerHeight()
          ;

          if (distanceBetween <= 0) {
              return;
          }

          jQuery('html, body').animate({
              scrollTop: hashTarget.offset().top - headerHeight
          }, 800);
      });
  }

  self.setupGetInTouchForm = function (form) {
    jQuery(form).validate({
      rules: {
        name: {
          required: true,
          minlength: 2
        },
        telephone: {
          required: false,
          telephone: true 
        },
        email: {
          required: true,
          email: true
        },
        message: {
          required: true,
          minlength: 1
        }
      },
      errorClass: 'error',
      validClass: 'valid',
      errorPlacement: function (error, element) {
        element.after(error);
      },
      submitHandler: function (form) {
        var
          form = jQuery(form),
          formData = form.serializeArray()
        ;

        if (typeof siteUrl === 'undefined') {
          return false;
        }

        form.trigger('ajax-submitting');
        form.find('.form-group').hide();
        form.find('.error-top-level').remove();

        jQuery.ajax({
          url: siteUrl + '/functions/form-submit.php',
          type: 'POST',
          data: formData,
          dataType: 'json',
          success: function (response) {
            if (response.success === 'true') {
              form.trigger('ajax-submitting-done');
            }
          },
          error: function (xhr, textStatus, error) {
            var regexp = new RegExp(/^[4|5]/);

            if (regexp.test(xhr.status)) {
              form.trigger('ajax-submitting-failed');
              form.prepend('<label class="error error-top-level">An unexpected error has occurred. Please try again.</label>');
            }
          }
        });
      }
    });
  };

  self.elementExists = function (sel) {
    return jQuery(sel).length > 0;
  };

  self.bindEvents = function () {
    self.setupValidatorDefaults();
    self.setupDocumentEvents();

    if (self.elementExists('a[href^="#"]')) {
      self.setupSmoothLinkScrolling('a[href^="#');
    }

    if (self.elementExists('.get-in-touch-form')) {
      self.setupGetInTouchForm('.get-in-touch-form');
    }
  };
  
  function init() {
    self.bindEvents();
  }

  return {
    getInstance: function () {
      if (!instance) {
          instance = init();
      }

      return instance;
    }
  };
})();

jQuery(document).ready(function () {
  var singleton = Singleton.getInstance();
});
